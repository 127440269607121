import React, { useState, useEffect } from 'react'
import logo from "../pics/logo.png";
import Input from '../components/input.js'

import { MapPinIcon } from '@heroicons/react/24/outline'
import { DevicePhoneMobileIcon, PhoneIcon } from '@heroicons/react/24/outline'
import { InboxIcon } from '@heroicons/react/24/outline'
import { Image, Affix, Badge, Descriptions, Card, Space, notification, Modal } from 'antd';
import { Button } from '../components/button.js'
import { Divider } from 'antd';
import { CommentOutlined, CustomerServiceOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';
import Slider from "react-slick";
import { Radio } from 'antd';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import pneuma from "../pics/pneuma.jpg";
import boxnail from "../pics/boxnail.jpg";



import palletSlider1 from "../pics/palletSlider1.jpg";
import palletSlider2 from "../pics/palletSlider2.jpg";
import palletSlider3 from "../pics/palletSlider3.jpg";
import palletSlider4 from "../pics/palletSlider4.jpeg";
import palletSlider5 from "../pics/palletSlider5.jpeg";
import palletSlider6 from "../pics/palletSlider6.jpg";
import palletSlider7 from "../pics/palletSlider7.jpg";
import palletSlider8 from "../pics/palletSlider8.jpg";

import pallet1200x800 from "../pics/1200-800.jpg";
import pallet1200x1000 from "../pics/1200-1000.jpeg";
import europallet from "../pics/europallet.jpg";

import pallet1200x1200 from "../pics/1200-1200.jpg";
import pallet2000x800 from "../pics/2000-800.jpg";
import palletCups from "../pics/pallet-cups.jpg";

import obreshotca from "../pics/obreshotca.jpg";
import pilomat from "../pics/pilomat.jpg";
import vegbox from "../pics/vegbox.jpg";

import speed from "../pics/speed.jpg";



import { useHttp } from '../hooks/http.hook'
import { useMessage } from '../hooks/message.hook'
import ventilyatsiya from "../pics/ventilyatsiya.jpg";

import { YMaps, Map, Placemark, ZoomControl, Polyline } from '@pbe/react-yandex-maps';


import { PhoneOutlined, WarningOutlined } from '@ant-design/icons';
import { Await } from 'react-router-dom';




export const Main = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { request } = useHttp()
  const [size, setSize] = useState('')
  const [count, setCount] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [modalName, setModalName] = useState('')
  const [modalPhone, setModalPhone] = useState('')

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [modalPolicy, setModalPolicy] = useState(false);
  const [radio, setRadio] = useState("1200х800");
  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    setRadio(e.target.value);
  };


  const openNotification = (status, message, description) => {

    notification.open({
      message: message,
      description: description,
      icon: status == "success" ? <PhoneOutlined style={{ color: '#007F43' }} /> : <WarningOutlined style={{ color: '#FF8C00' }} />,
    });
  };


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const [lastRequestTime, setLastRequestTime] = useState(0)




  // const sendPriceRequest = async () => {
  //   const currentTime = Date.now(); // Получаем текущее время
  //   try {
  //     if (phone.length >= 10) {
  //       // Проверяем, прошло ли уже 2 секунды с момента последнего запроса
  //       if (currentTime - lastRequestTime >= 2000) {
  //         openNotification("success", "Запрос отправлен!", "Отлично! Скоро с вами свяжется наш менеджер!");

  //         setLastRequestTime(currentTime) // Обновляем время последнего запроса
  //         const msg = await request('/api/all/sendPriceRequest', 'POST', { name, phone });
  //       } else {
  //         console.log("Слишком много запросов!")
  //         openNotification("warning", "Предупреждение!", "Слишком много запросов!");
  //       }
  //     } else {
  //       openNotification("warning", "Предупреждение!", "Проверьте корректность телефонного номера!");
  //     }
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }


  const sendMessage = async () => {
    const currentTime = Date.now(); // Получаем текущее время
    try {
      if (phone.length >= 10) {
        // Проверяем, прошло ли уже 2 секунды с момента последнего запроса
        if (currentTime - lastRequestTime >= 2000) {
          openNotification("success", "Запрос отправлен!", "Отлично! Скоро с вами свяжется наш менеджер!");

          setLastRequestTime(currentTime) // Обновляем время последнего запроса
          const msg = await request('/api/all/sendMessage', 'POST', { size, count, name, phone });
        } else {
          console.log("Слишком много запросов!")
          openNotification("warning", "Предупреждение!", "Слишком много запросов!");
        }
      } else {
        openNotification("warning", "Предупреждение!", "Проверьте корректность телефонного номера!");
      }
    } catch (e) {
      console.log(e)
    }
  }



  const sendMessageRadio = async () => {
    const currentTime = Date.now(); // Получаем текущее время
    try {
      if (phone.length >= 10) {
        // Проверяем, прошло ли уже 2 секунды с момента последнего запроса
        if (currentTime - lastRequestTime >= 2000) {
          openNotification("success", "Запрос отправлен!", "Отлично! Скоро с вами свяжется наш менеджер!");

          setLastRequestTime(currentTime) // Обновляем время последнего запроса
          const msg = await request('/api/all/sendPriceRequest', 'POST', { size: radio, count, name, phone });
        } else {
          console.log("Слишком много запросов!")
          openNotification("warning", "Предупреждение!", "Слишком много запросов!");
        }
      } else {
        openNotification("warning", "Предупреждение!", "Проверьте корректность телефонного номера!");
      }
    } catch (e) {
      console.log(e)
    }
  }



  const okModal = async () => {

    try {
      if (modalPhone.length >= 10) {
        openNotification("success", "Запрос отправлен!", "Отлично! Скоро с вами свяжется наш менеджер!");
        setModalOpen(false)
        const msg = await request('/api/all/sendMessage', 'POST', { size: "", count: "", name: modalName, phone: modalPhone });

      } else {
        openNotification("warning", "Предупреждение!", "Проверьте корректность телефонного номера!");
      }
    } catch (e) {
      console.log(e)
    }
  }

  const [openMoreImageModal, setOpenMoreImageModal] = useState(false)






  const [images, setImages] = useState([]);
  const [folder, setFolder] = useState("");

  useEffect(() => {
    const getImages = async () => {
      try {
        console.log("img")
        let img = await request('/api/all/getImages', 'POST', { folder: folder });
        console.log(img)
        setImages(img);
      } catch (error) {
        console.error('Ошибка при получении изображений:', error);
      }
    };

    if (openMoreImageModal) {
      getImages();
    }
  }, [openMoreImageModal]);


  const moreImageHandler = (folder) => {
    setFolder(folder)
    setOpenMoreImageModal(true)
  }


  return (
    <>


      <Modal
        title="Политика конфиденциальности"
        centered
        open={modalPolicy}
        width={1100}
        height={800}
        onCancel={() => setModalPolicy(false)}
        footer={[
          <Button key="back" onClick={() => setModalPolicy(false)}>
            Согласиться.
          </Button>,
        ]}

      >

        <div className='text-[20px] font-bold flex flex-col gap-1 font-normal w-full'>

          <iframe
            src="./politica.html"
            title="Example Website"

            height="300"
          ></iframe>


        </div>

      </Modal>


      <Modal
        title="Контейнер для овощей и фруктов"
        centered
        open={openMoreImageModal}
        onOk={() => setOpenMoreImageModal(false)}
        width={800}
        onCancel={() => setOpenMoreImageModal(false)}
        footer={[
          <Button key="back" onClick={() => { setModalOpen(true) }}>
            Заказать звонок!
          </Button>,
        ]}
      >

        <div className='text-[20px] font-bold flex flex-row gap-1 font-normal w-full'>



          <Image.PreviewGroup>
            {

              images.map((image, index) => (
                <Image key={index} width={200} src={image} />
              ))

            }

          </Image.PreviewGroup>


        </div>
      </Modal>




      <Modal
        title="Заполните форму,
        чтобы заказать обратный звонок!"
        centered
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        footer={[
          <Button key="back" onClick={() => okModal()}>
            Заказать звонок!
          </Button>,
        ]}
      >

        <div className='text-[20px] font-bold flex flex-col gap-1 font-normal w-full'>

          <Input
            className="text-blueGray-300 "
            placeholder="Имя"
            id="name"
            name="name"
            value={modalName}
            autoComplete="off"
            onChange={(e) => { setModalName(e.target.value) }}
          />

          <Input
            className="text-blueGray-300 w-[300px]"
            placeholder="Телефон"
            id="phone"
            name="phone"
            value={modalPhone}
            autoComplete="off"
            onChange={(e) => { setModalPhone(e.target.value) }}
          />

        </div>

      </Modal>

      <div className='flex flex-row justify-center w-full bg-fixed saturate-120 bg-center bg-cover palletParalaxtop h-[564px]'>

        {/* <div className='flex flex-row'> */}
        <div className='w-full max-w-[1350px] h-[125px] bg-[#fff] '>

          <div className='text-[20px] font-normal  flex flex-row p-3 justify-around items-center'>

            <div className='flex flex-row gap-3'>
              <img src={logo} className='w-[123px] h-[84px]' />
              <div className='flex flex-col justify-between'>
                <div className=''>Компания</div>
                <div className=''>ВОРОНЕЖСКИЙ ПОДДОН</div>
              </div>
            </div>

            <div className='flex flex-col '>
              <div className='flex flex-row gap-1'>  <MapPinIcon className="w-[16px]" /> г. Воронеж, ул. Острогожская, 151</div>
              <div className='flex flex-row gap-1'>  <DevicePhoneMobileIcon className="w-[16px]" />тел: 8-9191-800-777</div>
              <div className='flex flex-row gap-1'>  <InboxIcon className="w-[16px]" /><a href='mailto:poddon36@gmail.com'>e-mail: poddon36@gmail.com</a></div>
            </div>

          </div>

          <div className='flex flex-row w-full mt-[50px] justify-center gap-5'>

            <div className='text-[#fff]  w-1/2 max-w-[800px]  bg-[#221f2899]'>
              <p style={{ textAlign: "center" }}>
                <span className={windowWidth >= 950 ? 'text-[38px] font-bold items-center' : 'text-[28px] font-bold items-center'}     >
                  ИЗГОТОВЛЕНИЕ ДЕРЕВЯННЫХ <br></br> ПОДДОНОВ И КОНТЕЙНЕРОВ
                </span>
              </p>

              <div className='mt-[50px] flex flex-row justify-center'>
                <p style={{ textAlign: "left" }}>
                  <span className={windowWidth >= 950 ? 'text-[25px] font-bold items-center' : 'text-[24px] font-bold items-center'}>
                    Изготовим деревянную тару любых размеров по тех.<br></br> заданию заказчика<br></br>
                    Поможем с доставкой. Отправим в любой регион.<br></br>
                  </span>
                </p>
              </div>
            </div>

            <div className='w-1/2 max-w-[372px] h-[317px] bg-[#eeedf2] flex flex-col items-center justify-center'>

              <div className='text-[28px] font-semibold'>ОСТАВЬТЕ ЗАЯВКУ</div>

              <div className='text-[16px] px-9'>Наши менеджеры свяжутся с Вами для уточнения деталей</div>

              <div className='w-full py-4 px-8 flex flex-col gap-2'>


                <Input
                  className="text-blueGray-300 "
                  placeholder="Имя"
                  id="name"
                  name="name"
                  value={name}
                  autoComplete="off"
                  onChange={(e) => { setName(e.target.value) }}
                />

                <Input
                  className="text-blueGray-300 "
                  placeholder="Телефон*"
                  id="phone"
                  name="phone"
                  value={phone}
                  autoComplete="off"
                  onChange={(e) => { setPhone(e.target.value) }}
                />

                <Button
                  type="primary"
                  size="md"
                  className="mt-5"
                  onClick={() => { sendMessage() }}
                >
                  Оставить заявку
                </Button>

              </div>
            </div>



          </div>
        </div>
      </div>


      <div className='w-full  flex justify-center items-center'>
        <div className="text-[36px] font-['Arial'] font-normal mt-[30px] w-full max-w-[1100px] "><span>Оперативно изготовим нестандартные поддоны любых размеров по Вашим чертежам</span></div>

      </div>

      <center>
        <div className='w-full max-w-[1100px] mt-5'>

          <Slider className='flex flex-row' {...{
            arrows: true,
            draggable: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,

          }}>
            <div >
              <div className='flex flex-row gap-3 justify-center'>
                <Image src={palletSlider1} height={260} width={260} />
                <Image src={palletSlider2} height={260} width={260} />
                <Image src={palletSlider3} height={260} width={260} />
                <Image src={palletSlider4} height={260} width={260} />
              </div>
            </div>
            <div>
              <div className='flex flex-row gap-3 justify-center'>
                <Image src={palletSlider1} height={260} width={260} />
                <Image src={palletSlider2} height={260} width={260} />
                <Image src={palletSlider3} height={260} width={260} />
                <Image src={palletSlider4} height={260} width={260} />
              </div>
            </div>
          </Slider>

        </div>

        <Button
          type="secondary"
          size="md"
          color='secondary'
          className="mt-5 w-[300px] secondary"
          onClick={() => { setModalOpen(true) }}
        >
          Заказать
        </Button>
      </center>




      <div className='flex flex-col w-full  items-center justify-center  mt-5 px-2' >

        <div className=' max-w-[1100px] flex flex-col gap-5'>

          <center>
            <div className='flex flex-row gap-5 item-center content-center justify-center'>
              <div className='flex flex-col gap-5 w-full'>
                <Image className='rounded-lg' src={pallet1200x800} height={260} />



                <div className='select-none bg-[#589BB5] text-[18px] text-[#fff] rounded-xl flex items-center justify-center
                  hover:bg-[#72A5B2] hover:cursor-pointer active:bg-[#3F7BAF] h-[80px] mt-[5px] px-5 font-[Arial]'
                  onClick={() => { setModalOpen(true) }}>
                  Поддон 1200х800<br></br>Гост 9078-84
                </div>

              </div>


              <div className='flex flex-col gap-5  w-full'>
                <Image className='rounded-lg' src={pallet1200x1000} height={260} />



                <div className='select-none bg-[#589BB5] text-[18px] text-[#fff] rounded-xl flex items-center justify-center
                  hover:bg-[#72A5B2] hover:cursor-pointer active:bg-[#3F7BAF] h-[80px] mt-[5px] px-5 font-[Arial]'
                  onClick={() => { setModalOpen(true) }}>
                  Поддон 1200х1000 <br></br>ГоСТ 9078-84
                </div>

              </div>


              <div className='flex flex-col gap-5  w-full'>
                <Image className='rounded-lg w-full' src={europallet} height={260}  />


                <div className='select-none bg-[#589BB5] text-[18px] text-[#fff] rounded-xl flex items-center justify-center
                  hover:bg-[#72A5B2] hover:cursor-pointer active:bg-[#3F7BAF] h-[80px] mt-[5px] px-5 font-[Arial]'
                  onClick={() => { setModalOpen(true) }}>
                  Европаллета <br></br>ГоСТ 9557-87
                </div>

              </div>



            </div>


            <div className='w-full flex flex-row gap-5 item-center content-center justify-center mt-5'>

              <div className='flex flex-col gap-5 w-full'>
                <Image className='rounded-lg' src={pallet1200x1200} height={260} />



                <div className='select-none bg-[#589BB5] text-[18px] text-[#fff] rounded-xl flex items-center justify-center
                  hover:bg-[#72A5B2] hover:cursor-pointer active:bg-[#3F7BAF] h-[80px] mt-[5px] px-5 font-[Arial]'
                  onClick={() => { setModalOpen(true) }}>
                  Поддон 1200х1200 <br></br>ГоСТ 9078-84
                </div>


              </div>


              <div className='flex flex-col gap-5 w-full'>
                <Image className='rounded-lg' src={pallet2000x800} height={260} />




                <div className='select-none bg-[#589BB5] text-[18px] text-[#fff] rounded-xl flex items-center justify-center
                  hover:bg-[#72A5B2] hover:cursor-pointer active:bg-[#3F7BAF] h-[50px] mt-[5px] px-5 font-[Arial]'
                  onClick={() => { setModalOpen(true) }}>
                  Поддон 2000х800
                </div>

              </div>


              <div className='flex flex-col gap-5 w-full'>
                <Image className='rounded-lg' src={palletCups} height={260} />



                <div className='select-none bg-[#589BB5] text-[18px] text-[#fff] rounded-xl flex items-center justify-center
                  hover:bg-[#72A5B2] hover:cursor-pointer active:bg-[#3F7BAF] h-[50px] mt-[5px] px-5 font-[Arial]'
                  onClick={() => { setModalOpen(true) }}>
                  Паллетные крышки
                </div>

              </div>



            </div>

            <div className='w-full flex flex-row gap-5 item-center content-center justify-center mt-5'>


              <div className='flex flex-col gap-5 w-full'>
                <Image className='rounded-lg' src={obreshotca} height={260} />


                <div className='select-none bg-[#589BB5] text-[18px] text-[#fff] rounded-xl flex items-center justify-center
                  hover:bg-[#72A5B2] hover:cursor-pointer active:bg-[#3F7BAF] h-[50px] mt-[5px] px-5 font-[Arial]'
                  onClick={() => { setModalOpen(true) }}>
                  Обрешетка
                </div>

              </div>


              <div className='flex flex-col gap-5 w-full'>
                <Image className='rounded-lg' src={pilomat} height={260}


                />


                <div className='select-none bg-[#589BB5] text-[18px] text-[#fff] rounded-xl flex items-center justify-center
                  hover:bg-[#72A5B2] hover:cursor-pointer active:bg-[#3F7BAF] h-[50px] mt-[5px] px-5 font-[Arial]'
                  onClick={() => { setModalOpen(true) }}>
                  ПИЛОМАТЕРИАЛЫ
                </div>


              </div>


              <div className='flex flex-col gap-5 w-full'>
                <img src={vegbox} className='w-full h-[260px] rounded-lg hover:cursor-pointer hover:brightness-50 ' onClick={() => { moreImageHandler("box") }}></img>


                <div className='select-none bg-[#589BB5] text-[18px] text-[#fff] rounded-xl flex items-center justify-center
                  hover:bg-[#72A5B2] hover:cursor-pointer active:bg-[#3F7BAF] h-[80px] mt-[5px] px-5 font-[Arial]'
                  onClick={() => { setModalOpen(true) }}>
                  Контейнеры для <br></br>овощей и фруктов
                </div>


              </div>



            </div>


          </center>


          <Divider />







          <div className="text-[50px] font-['Roboto'] font-[100] mt-[30px] flex justify-center">Наши преимущества</div>


          <div className='flex flex-row  w-full justify-between mt-8 gap-3'>
            <div className='flex flex-col items-center gap-3'>
              <div><img src={pneuma} className='w-[208px] h-[226px]'></img></div>
              <p className='w-[290px] text-[18px] text-[#2c5871] font-semibold' style={{ textAlign: "center" }}>Сборка осуществляется пневмоинструментом, что существенно повышает качество и производительность производства</p>
            </div>

            <div className='flex flex-col items-center gap-3'>
              <div><img src={boxnail} className='w-[245px] h-[226px]'></img></div>
              <p className='w-[290px] text-[18px] text-[#2C5871] font-semibold' style={{ textAlign: "center" }}>Использование винтовых и ершеных гвоздей гарантирует надежное и долговременное соединение деталей поддона </p>
            </div>

            <div className='flex flex-col items-center gap-3'>
              <div><img src={speed} className='w-[245px] h-[226px]'></img></div>
              <p className='w-[290px] text-[18px] text-[#2C5871] font-semibold' style={{ textAlign: "center" }}>Изготовим Ваш заказ точно в срок </p>
            </div>
          </div>


        </div>



      </div >



      <footer
        class="flex items-center mt-5 h-[700px] w-full  bg-fixed bg-center bg-cover palletParalaxbottom saturate-200 justify-center items-center"
      >





        <div className='flex flex-col gap-[100px]'>

          <div className='w-[377px] h-[344px] bg-[#221f2899] mr-[100px] text-[#fff] font-[Arial] p-1'>


            <div className='flex flex-col gap-2'>
              <p className='text-[28px]'>ЗАКАЖИТЕ ДЕРЕВЯННУЮ ТАРУ НАПРЯМУЮ У ПРОИЗВОДИТЕЛЯ</p>

              <p className='text-[16px]'>Готовы изготовить любую деревянную тару по Вашим размерам</p>

              <p className='text-[16px]'>Работаем по наличному и безналичному рассчету</p>

              <p className='text-[16px]'>Произведем точный расчет стоимости</p>

              <p className='text-[16px]'>Предоставим полное информационное сопровождение о стадии готовности поставки</p>
            </div>


          </div>



          <div className='w-[920px] h-[146px] rounded-[15px] border-[2px] border-[#fff] p-4'>

            <div className='flex flex-row items-center justify-center gap-5'>

              <div className='flex flex-col w-1/3 gap-1'>
                <Radio.Group onChange={onChange} value={radio}>
                  <Radio value={"1200х800"}><div className='text-[20px] text-[#fff]'>1200х800</div></Radio>
                  <Radio value={"1200х1000"}><div className='text-[20px] text-[#fff]'>1200х1000</div></Radio>
                  <Radio value={"Нестандартные"}><div className='text-[20px] text-[#fff]'>Нестандартные</div></Radio>
                </Radio.Group>

                <Input
                  className="text-blueGray-300 "
                  placeholder="Количество"
                  id="countb"
                  name="countb"
                  value={count}
                  autoComplete="off"
                  onChange={(e) => { setCount(e.target.value) }}
                />
              </div>


              <div className='flex flex-col  w-1/3 gap-2 mt-2'>


                <Input
                  className="text-blueGray-300 "
                  placeholder="Ваше имя"
                  id="nameb"
                  name="nameb"
                  value={name}
                  autoComplete="off"
                  onChange={(e) => { setName(e.target.value) }}
                />

                <Input
                  className="text-blueGray-300 "
                  placeholder="Ваш телефон"
                  id="phoneb"
                  name="phoneb"
                  value={phone}
                  autoComplete="off"
                  onChange={(e) => { setPhone(e.target.value) }}
                />
              </div>
              <div className=' w-1/3 '>

                <div className='select-none bg-[#F99B1D] text-[20px] text-[#fff] rounded-xl flex items-center justify-center hover:bg-[#F7A33D] hover:cursor-pointer active:bg-[#F4890E] h-[100px] mt-[5px]'
                  onClick={() => { sendMessageRadio() }}>
                  Рассчитать<br></br>стоимость
                </div>


                {/* <Button
                type="primary"
                size="md"
                className="mt-5 w-full"
                color='gold'
                onClick={() => { sendMessage() }}
              >
                Рассчитать<br></br>стоимость
              </Button> */}

              </div>
            </div>

          </div>



        </div>

      </footer>


      <div className='flex flex-row items-center justify-center gap-[50px] h-[156px] bg-[#2c242b] font-[arial]'>
        <div className='flex flex-col text-[#fff] text-[18px] gap-5 '>
          <div>+7 (9191) 800-777</div>
          <div>e-mail: poddon36@gmail.com</div>
        </div>
        <div className='mb-5 text-[25px]'>

          <div className='select-none bg-[#589BB5] text-[25px] text-[#fff] rounded-xl flex items-center justify-center
          hover:bg-[#72A5B2] hover:cursor-pointer active:bg-[#3F7BAF] h-[50px] mt-[5px] px-5'
            onClick={() => { setModalOpen(true) }}>
            Заказать звонок
          </div>



        </div>
        <div className='flex flex-col items-center justify-center'>
          <div className='text-[#fff] text-[18px]'> г. Воронеж, ул.Острогожская, 151</div>
          <div className='hover:cursor-pointer text-[#fff]  hover:text-[#ccc]' onClick={() => { setModalPolicy(true) }} >Политика конфиденциальности</div>
        </div>

      </div>

      <YMaps >
        <div className='bg-[#cccccc] w-full ' >

          <Map instanceRef={ref => { ref && ref.behaviors.disable('scrollZoom'); }} width={"100%"} height={400} defaultState={{ center: [51.558427, 39.149316], zoom: 15 }} >
            <Polyline
              geometry={[
                [51.563330, 39.140041],
                [51.562963, 39.140117],
                [51.562963, 39.140117],
                [51.563023, 39.141255],
                [51.563023, 39.141255],
                [51.562642, 39.142619],
                [51.562642, 39.142619],
                [51.561840, 39.143181],
                [51.561840, 39.143181],
                [51.559583, 39.149331],
                [51.559583, 39.149331],
                [51.558889, 39.148754],
                [51.558889, 39.148754],
                [51.558533, 39.149514],
              ]}
              options={{
                balloonCloseButton: false,
                strokeColor: "#000",
                strokeWidth: 4,
                strokeOpacity: 0.5,
              }}
            />
            <Placemark geometry={[51.558427, 39.149316]} />
            <ZoomControl options={{ float: "left" }} />
            <div className='absolute w-[270px] h-[190px] bg-[#ffffff90] left-[100px] mt-[100px]' style={{ zIndex: 9999999999 }}>
              <div className='text-[28px] font-bold flex flex-row'><MapPinIcon className='w-[30px]' /> МЫ НА КАРТЕ</div>
              <div className='text-[22px]'>Адрес: Воронеж, ул. Острогожская, д. 151</div>
            </div>
          </Map>
        </div>
      </YMaps>









      {/* 
      <FloatButton.Group
      
        trigger="hover"
        type="primary"
        style={{
          right: 94,
        }}
        icon={ <PhoneIcon />}
       
      >
        <FloatButton  tooltip={<div>Documents</div>}/>
        <FloatButton icon={<CommentOutlined />} tooltip={<div>Documents</div>} />
      </FloatButton.Group> */}


    </>
  )
}

