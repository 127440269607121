

import { Button as AntButton, Spin, ConfigProvider } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import * as React from 'react'

import { arrayOf, bool, element, node, oneOf, oneOfType, string, func } from 'prop-types'
const antLoaderIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

export const Button = ({ loading, children, icon, color, className, size, ...props }) => {

    let classNames = ''
    switch (size) {
        case 'none':
            classNames = ' '
            break;
        case 'xs':
            classNames = ' py-1 px-2 text-sm rounded-lg'
            break;
        case 'lg':
            classNames = ' py-3 px-6 fs-lg font-medium rounded-md '
            break;
        case 'xl':
            classNames = ' py-4 px-8 fs-xl font-medium rounded-lg '
            break;
        default: // md
            classNames = 'py-[10px] px-4 text-lg rounded-xl font-semibold'
            break;
    }

    switch (color) {

        



        case 'gold':
            classNames += ` 
            bg-[#F99B1D]
            hover:bg-[#F99B1D] 
            active:[#F99B1D]
            hover:[#F99B1D]
            focus:outline-none 
            border-0
            hover:border-transparent
            text-white 
            hover:text-white 
            focus:text-white 
        `
            break;


        case 'secondary':
            classNames += ` 
            bg-[#589BB5]
            hover:bg-[#589BB5] 
            active:[#589BB5]
            focus:outline-none 
            border-0
            hover:border-transparent
            text-white 
            hover:text-white 
            focus:text-white 
        `
            break;


        default: //case 'primary':
            classNames += ` 
        bg-[#35B031]
        hover:bg-[#35B031] 
        active:[#35B031]
        focus:outline-none 
        border-0
        hover:border-transparent
        text-white 
        hover:text-white 
        focus:text-white 
      `
            break;

    }
    // 004323
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#007F43',
                   
                    // colorPrimaryHover: '#5435a1',
                },
            }}
        >
            <AntButton
                key={size}
                loading={loading}
                className={`
        h-max 
        active:scale-95 
        ${classNames} 
        ${className || ''}
      `}
                {...props}
            >
                {children}
                {loading && <Spin indicator={antLoaderIcon} />}
            </AntButton>
        </ConfigProvider>
    )
}

Button.propTypes = {
    size: oneOf(['none', 'xs', 'md', 'lg', 'xl']),
    color: oneOf(['primary', 'secondary', 'accent', 'danger', 'grey', 'ghost', 'gradient']),
    loading: bool,
    disabled: bool,
    className: string,
    onClick: func,
    children: oneOfType([
        arrayOf(node), node
    ])
}

Button.defaultProps = {
    size: 'lg',
    color: 'primary',
    loading: false,
    disabled: false,
}
