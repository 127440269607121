import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { NotFound } from './pages/NotFound'
import { Main } from './pages/Main'
import { Box } from './pages/Box'

function App() {
  return (
    <Router>
      <>
        <Routes>
          {/* <Route path="/" element={<Navigate to="/main" replace />} /> */}
          <Route path="/" element={<Main />} />
          <Route path="/box" element={<Box />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </>
    </Router>
  )
}
export default App;
