import React, { useState, useEffect } from 'react'
import logo from "../pics/logo.png";
import Input from '../components/input.js'
import { Link } from 'react-router-dom';
import { MapPinIcon } from '@heroicons/react/24/outline'
import { DevicePhoneMobileIcon, PhoneIcon } from '@heroicons/react/24/outline'
import { InboxIcon } from '@heroicons/react/24/outline'
import { Image, Affix, Badge, Descriptions, Card, Space, notification, Modal } from 'antd';
import { Button } from '../components/button.js'

import { CommentOutlined, CustomerServiceOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';


import box1 from "../pics/box1.jpg";
import box2 from "../pics/box2.jpg";
import box3 from "../pics/box3.jpg";
import box4 from "../pics/box4.jpg";

import build1 from "../pics/build1.jpg";
import build2 from "../pics/build2.jpg";
import build3 from "../pics/build3.jpg";
import build4 from "../pics/build4.jpg";
import build5 from "../pics/build5.jpg";
import build6 from "../pics/build6.jpg";
import build7 from "../pics/build7.jpg";
import build8 from "../pics/build8.jpg";
import build9 from "../pics/build9.jpg";
import build10 from "../pics/build10.jpg";

import pneuma from "../pics/pneuma.jpg";
import boxnail from "../pics/boxnail.jpg";

import somepic1 from "../pics/somepic1.jpg";
import somepic2 from "../pics/somepic2.jpg";

import box1200x1200x1000 from "../pics/box1200x1200x1000.jpeg";
import box1200x1200x10002 from "../pics/box1200x1200x1000-2.jpeg";
import boxForSelling from "../pics/boxForSelling.jpeg";
import boxForSelling2 from "../pics/boxForSelling2.jpeg";
import boxForSelling3 from "../pics/boxForSelling3.jpeg";
import box1200х1000х1000potato from "../pics/box1200х1000х1000potato.jpeg";
import boxTruck from "../pics/boxTruck.jpeg";


import { useHttp } from '../hooks/http.hook'
import { useMessage } from '../hooks/message.hook'
import ventilyatsiya from "../pics/ventilyatsiya.jpg";

import { YMaps, Map, Placemark, ZoomControl } from '@pbe/react-yandex-maps';


import { PhoneOutlined, WarningOutlined } from '@ant-design/icons';




export const Box = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalPolicy, setModalPolicy] = useState(false);
  const { request } = useHttp()
  const [size, setSize] = useState('')
  const [count, setCount] = useState('')
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);



  const openNotification = (status, message, description) => {

    notification.open({
      message: message,
      description: description,
      icon: status == "success" ? <PhoneOutlined style={{ color: '#007F43' }} /> : <WarningOutlined style={{ color: '#FF8C00' }} />,
    });
  };


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const [lastRequestTime, setLastRequestTime] = useState(0)


  const sendPriceRequest = async () => {
    const currentTime = Date.now(); // Получаем текущее время
    try {
      if (phone.length >= 10) {
        // Проверяем, прошло ли уже 2 секунды с момента последнего запроса
        if (currentTime - lastRequestTime >= 2000) {
          openNotification("success", "Запрос отправлен!", "Отлично! Скоро с вами свяжется наш менеджер!");

          setLastRequestTime(currentTime) // Обновляем время последнего запроса
          const msg = await request('/api/all/sendPriceRequest', 'POST', { size, count, name, phone });
        } else {
          console.log("Слишком много запросов!")
          openNotification("warning", "Предупреждение!", "Слишком много запросов!");
        }
      } else {
        openNotification("warning", "Предупреждение!", "Проверьте корректность телефонного номера!");
      }
    } catch (e) {
      console.log(e)
    }
  }




  const sendMessage = async () => {
    const currentTime = Date.now(); // Получаем текущее время
    try {
      if (phone.length >= 10) {
        // Проверяем, прошло ли уже 2 секунды с момента последнего запроса
        if (currentTime - lastRequestTime >= 2000) {
          openNotification("success", "Запрос отправлен!", "Отлично! Скоро с вами свяжется наш менеджер!");

          setLastRequestTime(currentTime) // Обновляем время последнего запроса
          const msg = await request('/api/all/sendMessage', 'POST', { size, count, name, phone });
        } else {
          console.log("Слишком много запросов!")
          openNotification("warning", "Предупреждение!", "Слишком много запросов!");
        }
      } else {
        openNotification("warning", "Предупреждение!", "Проверьте корректность телефонного номера!");
      }
    } catch (e) {
      console.log(e)
    }
  }

  const [modalName, setModalName] = useState('')
  const [modalPhone, setModalPhone] = useState('')

  const okModal = async () => {

    try {
      if (modalPhone.length >= 10) {
        openNotification("success", "Запрос отправлен!", "Отлично! Скоро с вами свяжется наш менеджер!");
        setModalOpen(false)
        const msg = await request('/api/all/sendMessage', 'POST', { size: "", count: "", name: modalName, phone: modalPhone });

      } else {
        openNotification("warning", "Предупреждение!", "Проверьте корректность телефонного номера!");
      }
    } catch (e) {
      console.log(e)
    }
  }




  return (
    <>



      <Modal
        title="Заполните форму,
        чтобы заказать обратный звонок!"
        centered
        open={modalOpen}

        onCancel={() => setModalOpen(false)}
        footer={[
          <Button key="back" onClick={() => okModal()}>
            Заказать звонок!
          </Button>,
        ]}
      >

        <div className='text-[20px] font-bold flex flex-col gap-1 font-normal w-full'>

          <Input
            className="text-blueGray-300 "
            placeholder="Имя"
            id="name"
            name="name"
            value={modalName}
            autoComplete="off"
            onChange={(e) => { setModalName(e.target.value) }}
          />

          <Input
            className="text-blueGray-300 w-[300px]"
            placeholder="Телефон"
            id="phone"
            name="phone"
            value={modalPhone}
            autoComplete="off"
            onChange={(e) => { setModalPhone(e.target.value) }}
          />

        </div>

      </Modal>




      <Modal
        title="Политика конфиденциальности"
        centered
        open={modalPolicy}
        width={1100}
        height={800}
        onCancel={() => setModalPolicy(false)}
        footer={[
          <Button key="back" onClick={() => setModalPolicy(false)}>
            Согласиться.
          </Button>,
        ]}

      >

        <div className='text-[20px] font-bold flex flex-col gap-1 font-normal w-full'>

          <iframe
            src="./politica.html"
            title="Example Website"

            height="300"
          ></iframe>


        </div>

      </Modal>



      <div className='flex flex-row justify-center w-full bg-fixed saturate-120 bg-center bg-cover backbox-img h-[617px]'>

        {/* <div className='flex flex-row'> */}
        <div className='w-full max-w-[1350px] h-[125px] bg-[#fff] '>

          <div className='text-[20px] font-normal  flex flex-row p-3 justify-around items-center'>

            <div className='flex flex-row gap-3'>
              <Link to="http://poddon36.ru">
                <img src={logo} className="w-[123px] h-[84px] hover:cursor-pointer" alt="Logo" />
              </Link>
              <div className='flex flex-col justify-between'>
                <div className=''>Компания</div>
                <div className=''>ВОРОНЕЖСКИЙ ПОДДОН</div>
              </div>
            </div>

            <div className='flex flex-col '>
              <div className='flex flex-row gap-1'>  <MapPinIcon className="w-[16px]" /> г. Воронеж, ул. Острогожская, 151</div>
              <div className='flex flex-row gap-1'>  <DevicePhoneMobileIcon className="w-[16px]" />тел: 8-9191-800-777</div>
              <div className='flex flex-row gap-1'>  <InboxIcon className="w-[16px]" /><a href='mailto:poddon36@gmail.com'>e-mail: poddon36@gmail.com</a></div>
            </div>

          </div>

          <div className='flex flex-row w-full mt-[50px] justify-center'>

            <div className='text-[#fff] color-[#000] w-1/2 max-w-[800px] '>
              <p style={{ textAlign: "center" }}>
                <span style={{ backgroundColor: "#000" }} className={windowWidth >= 950 ? 'text-[38px] font-bold items-center' : 'text-[28px] font-bold items-center'}     >
                  ИЗГОТОВЛЕНИЕ ДЕРЕВЯННЫХ <br></br> ЯЩИКОВ И КОНТЕЙНЕРОВ <br></br> ДЛЯ ОВОЩЕХРАНИЛИЩ
                </span>
              </p>

              <div className='mt-[50px] flex flex-row justify-center'>
                <p style={{ textAlign: "left" }}>
                  <span style={{ backgroundColor: "#000" }} className={windowWidth >= 950 ? 'text-[25px] font-bold items-center' : 'text-[24px] font-bold items-center'}>
                    -Любых размеров по ТЗ заказчика.<br></br>
                    -Доставка в регионы<br></br>
                    -Любая форма оплаты
                  </span>
                </p>
              </div>
            </div>

            <div className='w-1/2 max-w-[350px] h-[393px] bg-[#eeedf2] flex flex-col items-center'>

              <div className='text-[28px] font-semibold'>Получите расчет</div>

              <div className='text-[16px]'>Стоимости необходимых контейнеров</div>

              <div className='w-full py-4 px-8 flex flex-col gap-2'>
                <Input
                  className="text-blueGray-300 "
                  placeholder="Размер"
                  id="size"
                  name="size"
                  value={size}
                  autoComplete="off"
                  onChange={(e) => { setSize(e.target.value) }}
                />

                <Input
                  className="text-blueGray-300 "
                  placeholder="Кол-во, шт."
                  id="count"
                  name="count"
                  value={count}
                  autoComplete="off"
                  onChange={(e) => { setCount(e.target.value) }}
                />

                <Input
                  className="text-blueGray-300 "
                  placeholder="Имя"
                  id="name"
                  name="name"
                  value={name}
                  autoComplete="off"
                  onChange={(e) => { setName(e.target.value) }}
                />

                <Input
                  className="text-blueGray-300 "
                  placeholder="Телефон*"
                  id="phone"
                  name="phone"
                  value={phone}
                  autoComplete="off"
                  onChange={(e) => { setPhone(e.target.value) }}
                />

                <Button
                  type="primary"
                  size="md"
                  className="mt-5"
                  onClick={() => { sendPriceRequest() }}
                >
                  Получить расчёт
                </Button>

              </div>
            </div>



          </div>
        </div>
      </div>


      <div className='flex flex-col w-full  items-center justify-center  mt-5 px-2' >

        <div className='max-w-[1100px]'>

          {/* <div className='w-full flex flex-row gap-5'>
            <Image src={box1} />
            <Image src={box2} />
            <Image src={box3} />
            <Image src={box4} />



          </div> */}


          <div className='w-full flex flex-row gap-5'>
            <Card
              hoverable
              style={{

              }}
              cover={<Space size={12}>
                <Image src={box1} height={210} />
              </Space>
              }
            >
              <p>Описание</p>
            </Card>


            <Card
              hoverable
              style={{

              }}
              cover={<Space size={12}>
                <Image src={box2} height={210} />
              </Space>
              }
            >
              <p>Описание</p>
            </Card>


            <Card
              hoverable
              style={{

              }}
              cover={<Space size={12}>
                <Image src={box3} height={210} />
              </Space>
              }
            >
              <p>Описание</p>
            </Card>


            <Card
              hoverable
              style={{

              }}
              cover={<Space size={12} >
                <Image src={box4} height={210} />
              </Space>
              }
            >
              <p>Описание</p>
            </Card>




          </div>



          <div className='w-full flex flex-row gap-5 mt-5'>
            <Card
              hoverable
              style={{

              }}
              cover={<Space size={12}>
                <Image src={box1200x1200x1000} height={210} width={195} />
              </Space>
              }
            >
              <p>Контейнер 1200х1000х1000 для картофеля, моркови, свеклы</p>
            </Card>


            <Card
              hoverable
              style={{

              }}
              cover={<Space size={12}>
                <Image src={box1200x1200x10002} height={210} width={195} />
              </Space>
              }
            >
              <p>Контейнер 1200х1000х1000 для картофеля, моркови, свеклы</p>
            </Card>


            <Card
              hoverable
              style={{

              }}
              cover={<Space size={12}>
                <Image src={box1200х1000х1000potato} height={210} width={195} />
              </Space>
              }
            >
              <p>Контейнер 1200х1000х1000 для картофеля, моркови, свеклы</p>
            </Card>

            {/* 
            <Card
              hoverable
              style={{

              }}
              cover={<Space size={12} >
                <Image src={boxTruck} height={210} />
              </Space>
              }
            >
              <p>Описание</p>
            </Card> */}


            <Card
              hoverable
              style={{

              }}
              cover={<Space size={12}>
                <Image src={boxForSelling} height={210} className='w-1/4' />
              </Space>
              }
            >
              <p>Выставочный ящик для торговли</p>
            </Card>


            <Card
              hoverable
              style={{

              }}
              cover={<Space size={12}>
                <Image src={boxForSelling2} height={210} />
              </Space>
              }
            >
              <p>Выставочный ящик для торговли</p>
            </Card>


            <Card
              hoverable
              style={{

              }}
              cover={<Space size={12}>
                <Image src={boxForSelling3} height={210} />
              </Space>
              }
            >
              <p>Выставочный ящик для торговли</p>
            </Card>




          </div>









          <div className='w-full flex flex-col gap-3 border-[1px] border-[#ccc] rounded p-3 mt-5 mb-5'>
            <p className='text-[20px]'>
              Наши деревянные контейнеры подходят для хранения и транспортировки овощей
              (картофеля, капусты, моркови, лука) и крупных фруктов (яблоки, груши)
            </p>



            <p className='text-[18px]'>Поддон и борта собраны винтовыми и кольцевыми гвоздями
              пневмоинструментом </p>

            <p className='text-[18px]'>Поставляются в разобраном виде под сборку саморезами,
              гвоздями или болтами (прилагаются в комплекте) </p>

            <p className='text-[18px]'>
              Характеристики:<br></br>
              Грузоподъемность - от 1000 кг<br></br>
              Материал изделия - сосна
            </p>


            <p className='text-[18px]'> Основные размеры:
              -1200х800х800 (~0,75 куб. м.)<br></br>
              -1200х1000х800 (~0,95 куб. м.)<br></br>
              -1600х1200х1200 (~2,2 куб. м.)
            </p>

            <p className='text-[18px]'>Изготовим деревянную тару по Вашим размерам.
              Любая форма оплаты.
            </p>
            <p className='text-[18px]'> Отправим в регионы. (100-250 комплектов в фуре в разобранном виде)
            </p>
          </div>

          <Image src={ventilyatsiya} className='w-full' />

          <p className="text-[50px] font-['Roboto'] font-[100] mt-[30px] flex justify-center">Простая самостоятельная сборка</p>

          <div className='w-full flex flex-col gap-4 mt-[30px] items-center'>

            <Image.PreviewGroup
              preview={{
                onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
              }}

            >
              <div className='flex flex-row gap-4'>
                <Image src={build1} className='w-1/5' />
                <Image src={build2} className='w-1/5' />
                <Image src={build3} className='w-1/5' />
                <Image src={build4} className='w-1/5' />
                <Image src={build5} className='w-1/5' />
              </div>
              <div className='flex flex-row gap-4'>
                <Image src={build6} className='w-1/5 ' />
                <Image src={build7} className='w-1/5' />
                <Image src={build8} className='w-1/5' />
                <Image src={build9} className='w-1/5' />
                <Image src={build10} className='w-1/5' />
              </div>
            </Image.PreviewGroup>



          </div>

          <div className='flex flex-row  w-full justify-between mt-8 gap-5'>

            <div className='w-1/2'>
              <iframe className='video w-full h-[304px]'
                title='Youtube player'
                sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                src={`https://youtube.com/embed/GVkRSRjpywg?autoplay=0`}>
              </iframe>
            </div>
            <div className='w-1/2'>
              <iframe className='video w-full h-[304px]'
                title='Youtube player'
                sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation'
                src={`https://youtube.com/embed/yhMgvVpPBcs?autoplay=0`}>
              </iframe>
            </div>
          </div>





          <div className="text-[50px] font-['Roboto'] font-[100] mt-[30px] flex justify-center">Качественно и долговечно</div>


          <div className='flex flex-row  w-full justify-between mt-8 gap-3'>
            <div className='flex flex-row items-center'>
              <div><img src={pneuma} className='w-[208px] h-[247px]'></img></div>
              <p className='w-[220px] text-[18px] text-[#2c5871] font-semibold' style={{ textAlign: "center" }}>Сборка осуществляется пневмоинструментом, что существенно повышает качество и производительность производства</p>
            </div>

            <div className='flex flex-row items-center'>
              <p className='w-[220px] text-[18px] text-[#2C5871] font-semibold' style={{ textAlign: "center" }}>Использование винтовых и ершеных гвоздей гарантирует надежное и долговременное соединение деталей поддона </p>
              <div><img src={boxnail} className='w-[245px] h-[226px]'></img></div>
            </div>
          </div>






          <p className="text-[50px] font-['Roboto'] font-[100] mt-[30px] text-center">Быстрая доставка в регионы РФ</p>


          <div className='flex flex-row  w-full justify-between mt-8 gap-5'>
            <div className='flex flex-row items-center w-1/2'>
              <Image src={somepic1} className='w-full' />
            </div>

            <div className='flex flex-row items-center w-1/2'>
              <Image src={somepic2} className='w-full' />
            </div>
          </div>








        </div>



      </div >

      <footer
        class="flex items-center mt-5 h-[700px] w-full  bg-fixed bg-center bg-cover footer-paralax saturate-200 justify-center items-center"
      >

        <div className='w-[460px] h-[393px] bg-[#eeedf2] flex flex-col items-center'>

          <div className='text-[28px] font-semibold'>Оставьте заявку</div>

          <div className='text-[16px]'>на расчет стоимости контейнеров</div>

          <div className='w-full py-4 px-8 flex flex-col gap-2'>


            <Input
              className="text-blueGray-300 "
              placeholder="Размер"
              id="sizeb"
              name="sizeb"
              value={size}
              autoComplete="off"
              onChange={(e) => { setSize(e.target.value) }}
            />

            <Input
              className="text-blueGray-300 "
              placeholder="Количество"
              id="countb"
              name="countb"
              value={count}
              autoComplete="off"
              onChange={(e) => { setCount(e.target.value) }}
            />

            <Input
              className="text-blueGray-300 "
              placeholder="Ваше имя"
              id="nameb"
              name="nameb"
              value={name}
              autoComplete="off"
              onChange={(e) => { setName(e.target.value) }}
            />

            <Input
              className="text-blueGray-300 "
              placeholder="Ваш телефон"
              id="phoneb"
              name="phoneb"
              value={phone}
              autoComplete="off"
              onChange={(e) => { setPhone(e.target.value) }}
            />

            <Button
              type="primary"
              size="md"
              className="mt-5"
              onClick={() => { sendPriceRequest() }}
            >
              Получить расчёт
            </Button>

          </div>
        </div>

      </footer>

      <YMaps >
        <div className='bg-[#cccccc] w-full ' >

          <Map instanceRef={ref => { ref && ref.behaviors.disable('scrollZoom'); }} width={"100%"} height={400} defaultState={{ center: [51.557382, 39.150440], zoom: 15 }} >
            <Placemark geometry={[51.557382, 39.150440]} />
            {/* <Placemark geometry={[51.563235, 39.143702]} /> */}
            <ZoomControl options={{ float: "left" }} />
            <div className='absolute w-[270px] h-[190px] bg-[#ffffff90] left-[100px] mt-[100px] p-2' style={{ zIndex: 10 }}>
              <div className='text-[28px] font-bold flex flex-row'><MapPinIcon className='w-[30px]' /> МЫ НА КАРТЕ</div>
              <div className='text-[22px]'>Адрес: Воронеж, ул. Острогожская, д. 151</div>
            </div>
          </Map>
        </div>
      </YMaps>






      <div className='w-full h-200px flex flex-row justify-around items-center py-3'>

        <Link to="http://poddon36.ru">
          <img src={logo} className='w-[173px] h-[115px] hover:cursor-pointer' />
        </Link>

        <div className='flex flex-col'>
          {/* <div className='flex flex-row gap-1 text-[#0066CC] text-[24px] hover:cursor-pointer hover:text-[#0099FF]'>
            <DevicePhoneMobileIcon className="w-[16px]" /><u>+7 (473) 229-88-71</u>
          </div> */}
          <div className='flex flex-row gap-1 text-[#0066CC] text-[24px] hover:cursor-pointer hover:text-[#0099FF]'>
            <DevicePhoneMobileIcon className="w-[16px]" /><u>+7 (9191) 800-777</u>
          </div>
          <div className='hover:cursor-pointer hover:text-[#ccc]' onClick={() => { setModalOpen(true) }} >ЗАКАЗАТЬ ЗВОНОК</div>
          <div className='hover:cursor-pointer hover:text-[#ccc]' onClick={() => { setModalPolicy(true) }} >Политика конфиденциальности</div>
        </div>
      </div>


      {/* 
      <FloatButton.Group
      
        trigger="hover"
        type="primary"
        style={{
          right: 94,
        }}
        icon={ <PhoneIcon />}
       
      >
        <FloatButton  tooltip={<div>Documents</div>}/>
        <FloatButton icon={<CommentOutlined />} tooltip={<div>Documents</div>} />
      </FloatButton.Group> */}


    </>
  )
}

