import { ConfigProvider, Input as AntInput } from 'antd'
import * as React from 'react'

import { QuestionMarkCircleIcon, SearchIcon, PaperAirplaneIcon } from '@heroicons/react/outline'

// import 'antd/lib/input/style/index.css'

const InputModal = ({ error, className, parentClass, ...props }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#007F43',
          // colorPrimaryHover: '#5435a1',
        },
      }}
    >
      <div className={`${parentClass ? parentClass : ''}`}>
        <div className="relative">

        {/*pr-20*/}

        <AntInput
          className={`
            inline-flex
            px-2.5
            w-full
            fs-sm

            text-black
            bg-white
            rounded-lg
            border-1
            appearance-none
            shadow-sm
   
            peer
            focus:ring-0 
            
            ${props?.slim ? 'pb-1.5 pt-1.5' : 'pb-3 pt-3'}            
            
            hover:border-primary-violet
            
            ${className ? className : ''}
          `}

          placeholder=' '
          // onClick={e => {e.target.placeholder = ''}}
          {...props}
          status={error && error.text && 'error'}
        />

          {/*text-red-600*/}
          {/*peer-focus:text-red-600*/}

          {props?.id && props?.label &&
            <label htmlFor={props.id} className="
          absolute
          fs-sm
          text-gray-400

          left-1
          duration-200
          transform
          -translate-y-6
          scale-75
          top-2.5
          z-10
          origin-[0]
          bg-white
          px-2

          peer-placeholder-shown:scale-100
          peer-placeholder-shown:-translate-y-3
          peer-placeholder-shown:top-1/2
          peer-placeholder-shown:left-2

          peer-focus:left-1
          peer-focus:top-2.5
          peer-focus:scale-75
          peer-focus:-translate-y-6
          peer-focus:text-gray-400
          ">{props.label}</label>
          }

          {props?.showIcons &&
            <div className={`absolute ${props?.slim ? 'top-2' : 'top-3'} right-2.5 inline-flex items-center gap-2 bg-white`}>
              {props?.searchIcon && <SearchIcon className='h-5 text-char-secondary cursor-pointer' strokeWidth={1.5}  onClick={props?.searchIconClick || (() => {})}/>}
              {props?.questionIcon &&
                <>
                  <div className='w-0.5 h-6 bg-func-hover-dark'/>
                  <QuestionMarkCircleIcon className='h-5 text-char-secondary cursor-pointer' strokeWidth={1.5} onClick={props?.questionIconClick || (() => {})}/>
                </>
              }
              {props?.sendIcon &&
                <>
                  <div className='w-0.5 h-6 bg-func-hover-dark'/>
                  <PaperAirplaneIcon className='h-5 text-char-secondary rotate-90 cursor-pointer' strokeWidth={1.5} onClick={props?.sendIconClick || (() => {})}/>
                </>
              }
            </div>
          }

        </div>
     
      </div>
    </ConfigProvider>
    )
}

export default InputModal